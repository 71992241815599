import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import {
  ConfigResource,
  ConfigResponse,
  LocalizedLocalizationResource,
} from "interfaces/resourceService";
import { RootState } from "app/store";
import { mapObject } from "app/utils";

interface ResourceServiceState {
  configuration: ConfigResource;
  localizations: LocalizedLocalizationResource;
  resourcesLoaded: boolean;
}

export const initialState: ResourceServiceState = {
  configuration: {
    l: "",
    p: "",
    AppDateFormat: "",
    routes: {},
    system: "",
    origins: [],
    minDestinNo: 0,
    userToken: "",
    dialPrefix: {},
    bannerFileName: "",
    earliestDeparture: 0,
    latestDeparture: 0,
    latestReturn: 0,
    pciIframeUrl: "",
    allowedEventOrigin: "",
    merchantId: "",
    selectableDestinations: false,
    priceComparisonEnabled: false,
    enabledCards: [],
    tokenizerScriptUrl: "",
    paymentRedirectErrors: [],
    langs: [],
    crosslinks: {},
    inRelease: false
  },
  localizations: {},
  resourcesLoaded: false,
};

export const resourceServiceSlice = createSlice({
  name: "resourceService",
  initialState,
  reducers: {
    setResourceServices: (state, action: PayloadAction<ConfigResponse>) => {
      const payload = action.payload;
      state.configuration = mapObject(
        payload,
        Object.keys(payload).filter(
          (key) => key !== "textids",
        ) as (keyof ConfigResource)[],
      );

      payload.langs.forEach((lang: string) => {
        state.localizations[lang] = payload.textids[lang];
      });
      state.resourcesLoaded = true;
    },
  },
});

export const { setResourceServices } = resourceServiceSlice.actions;

export const selectLocalizations = (state: RootState) =>
  state.resourceService.localizations;
export const selectConfiguration = (state: RootState) =>
  state.resourceService.configuration;
export const selectResourcesLoaded = (state: RootState) =>
  state.resourceService.resourcesLoaded;

export default resourceServiceSlice.reducer;
